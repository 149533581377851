"use strict";

jQuery(document).ready(function () {
  var containers = jQuery('.container');
  if (containers.length) {
    containers.each(function () {
      var container = jQuery(this);

      // Support small text - copy to fill screen width
      if (container.find('.scrolling-text').outerWidth() < jQuery(window).width()) {
        var windowToScrolltextRatio = Math.round(jQuery(window).width() / container.find('.scrolling-text').outerWidth()),
          scrollTextContent = container.find('.scrolling-text .scrolling-text-content').text(),
          newScrollText = '';
        for (var i = 0; i < windowToScrolltextRatio; i++) {
          newScrollText += ' ' + scrollTextContent;
        }
        container.find('.scrolling-text .scrolling-text-content').text(newScrollText);
      }

      // Init variables and config
      var scrollingText = container.find('.scrolling-text'),
        scrollingTextWidth = scrollingText.outerWidth(),
        scrollingTextHeight = scrollingText.outerHeight(true),
        startLetterIndent = parseInt(scrollingText.find('.scrolling-text-content').css('font-size'), 10) / 4.8,
        startLetterIndent = Math.round(startLetterIndent),
        scrollAmountBoundary = Math.abs(jQuery(window).width() - scrollingTextWidth),
        transformAmount = 100,
        transformDirection = container.hasClass('left-to-right') ? -1 : 1,
        transformSpeed = 5;

      // Read transform speed
      if (container.attr('speed')) {
        transformSpeed = container.attr('speed');
      }
      var getActiveScrollingText = function getActiveScrollingText(direction) {
        var firstScrollingText = container.find('.scrolling-text:nth-child(1)');
        var secondScrollingText = container.find('.scrolling-text:nth-child(2)');
        var firstScrollingTextLeft = parseInt(container.find('.scrolling-text:nth-child(1)').css("left"), 10);
        var secondScrollingTextLeft = parseInt(container.find('.scrolling-text:nth-child(2)').css("left"), 10);
        if (direction === 'left') {
          return firstScrollingTextLeft < secondScrollingTextLeft ? secondScrollingText : firstScrollingText;
        } else if (direction === 'right') {
          return firstScrollingTextLeft > secondScrollingTextLeft ? secondScrollingText : firstScrollingText;
        }
      };
      jQuery(window).on('wheel', function (e) {
        if (jQuery(window).scrollTop() !== 0) {
          var delta = e.originalEvent.deltaY;
          if (delta > 0) {
            // going down
            transformAmount += transformSpeed * transformDirection;
          } else {
            transformAmount -= transformSpeed * transformDirection;
          }
          setTimeout(function () {
            container.find('.scrolling-text').css('transform', 'translate3d(' + transformAmount * -1 + 'px, 0, 0)');
          }, 10);
          setTimeout(function () {
            container.find('.scrolling-text .scrolling-text-content').css('transform', 'skewX(0)');
          }, 500);
        }
      });
    });
  }
});