"use strict";

jQuery(document).ready(function ($) {
  function initializeSlick() {
    if ($(window).width() <= 991) {
      if (!$('.clients-slider').hasClass('slick-initialized')) {
        $('.clients-slider').slick({
          slidesToShow: 2,
          slidesToScroll: 2,
          arrows: true,
          dots: true,
          infinite: true,
          adaptiveHeight: true,
          prevArrow: $('.arrow-prev'),
          nextArrow: $('.arrow-next')
        });

        // Add margin between slides
        $('.clients-slider .slick-slide').css('margin-right', '10px');
      }
    } else {
      if ($('.clients-slider').hasClass('slick-initialized')) {
        $('.clients-slider').slick('unslick');
        $('.clients-slider .slick-slide').css('margin-right', '0'); // Reset margin
      }
    }
  }

  // Initialize slick on document ready
  initializeSlick();

  // Reinitialize slick on window resize
  $(window).resize(function () {
    initializeSlick();
  });
});