"use strict";

jQuery(document).ready(function ($) {
  // Foundation Initialization
  $(document).foundation();

  // Navigation Menu Toggle
  var body = $("body");
  var nav = $(".nav-container");
  $("#openMenu").click(function () {
    nav.addClass("open");
    body.addClass("open");
  });
  $("#close-menu").click(function () {
    nav.removeClass("open");
    body.removeClass("open");
  });
  $(document).mouseup(function (e) {
    if (!nav.is(e.target) && nav.has(e.target).length === 0) {
      body.removeClass("open");
      nav.removeClass("open");
    }
  });

  // Magnific Popup
  $('.popup-youtube, .popup-vimeo, .popup-gmaps').magnificPopup({
    type: 'iframe',
    mainClass: 'mfp-fade',
    removalDelay: 160,
    preloader: false,
    fixedContentPos: false
  });

  // Site Navigation Children Classes
  var ul = $("nav#site-navigation ul li ul");
  var li = ul.closest("li");
  li.addClass("hasChildren");
  li.find("a").append("<a href='javascript:void(0)' class='expandChild' title='Child item'></a>");

  // Search Toggle
  $(".open-search-from").click(function () {
    $("section").addClass("open-search");
    body.addClass("search-main");
  });
  $("#deleteSearch").click(function () {
    $("section").removeClass("open-search");
    body.removeClass("search-main");
  });

  // Scroll Animation
  $(window).on('scroll touchmove', function () {
    $('.fade-in').each(function () {
      if (isScrolledIntoView($(this))) {
        $(this).addClass('animation-started');
      }
    });
  });

  // Form Tab Behavior
  var formTab = $("#gform_target_page_number_3").val();
  if (formTab === '2') {
    handleFormTab(false);
  } else {
    handleFormTab(true);
  }
  $("#submitForm").click(function () {
    $("#gform_submit_button_3").click();
  });
  function handleFormTab(isFirstTab) {
    var firstTabTitle = $('.firstTabTitle');
    var secondTabTitle = $('.secondTabTitle');
    var moduleFooter = $('.module-footer ul');
    if (isFirstTab) {
      $(".gform_next_button").hide();
      $(".gform_previous_button").show();
      firstTabTitle.hide();
      secondTabTitle.show();
      moduleFooter.show();
    } else {
      $(".gform_previous_button").hide();
      $("#submitForm").hide();
      firstTabTitle.show();
      secondTabTitle.hide();
      moduleFooter.hide();
    }
  }

  // Options Handling with Cookies
  initializeCookies();
  function initializeCookies() {
    var cookieName = 'options';
    var packageId = $('#package-options').data('package-id');
    var optionsArray = Cookies.get(cookieName) ? Cookies.get(cookieName).split(',') : [];
    if (!Cookies.get(cookieName)) {
      Cookies.set(cookieName, JSON.stringify([]));
      Cookies.set('options_package_id', packageId);
    }
    setupOptionHandlers(optionsArray);
  }
  function setupOptionHandlers(optionsArray) {
    setTimeout(function () {
      $('#input_3_27').val(0);
      $('#input_3_4').val(optionsArray.join());
      optionsArray.forEach(function (id) {
        $(".option-cards article[data-id='" + id + "']").click();
      });
    }, 10);
    $("ul.added-options").on("click", "li", function () {
      var id = $(this).data('option-id');
      $(this).remove();
      optionsArray.splice(optionsArray.indexOf(id), 1);
      Cookies.set('options', optionsArray.join());
      $(".option-cards article[data-id='" + id + "']").click();
    });
    $(".option-cards article").each(function () {
      var _this = this;
      var toggle = true;
      $(this).click(function () {
        var title = $(_this).find('.title').text();
        var id = $(_this).data('id');
        $(_this).toggleClass("is-selected");
        if (toggle) {
          optionsArray.push(id);
          $(".added-options").append("<li data-option-id=\"".concat(id, "\"><i class=\"far fa-trash-alt\"></i>").concat(title, "</li>"));
        } else {
          optionsArray.splice(optionsArray.indexOf(id), 1);
          $(".added-options li[data-option-id=\"".concat(id, "\"]")).remove();
        }
        Cookies.set('options', optionsArray.join());
        toggle = !toggle;
      });
    });
  }

  // Form Input Display Handlers
  setupFormInput("#input_3_6", '#formDate', '#formDateContainer', '.form-information');
  setupFormInput("#input_3_25", '#formTime', '#formTimeContainer');
  setupFormInput("#input_3_8", '#formAdres', '#formAdresContainer');
  setupFormInput("#input_3_12", '#formPersons', '#formPersonContainer');
  setupRadioInput("#input_3_26", '#formFoodtruck', '#formFoodtruckContainer');
  function setupFormInput(inputSelector, displaySelector, containerSelector, extraContainer) {
    var oldValue = $(inputSelector).val();
    if (oldValue) {
      $(displaySelector).text(oldValue);
      $(containerSelector).show();
      if (extraContainer) $(extraContainer).show();
    }
    $(inputSelector).change(function () {
      var value = $(this).val();
      if (value) {
        $(displaySelector).text(value);
        $(containerSelector).show();
        if (extraContainer) $(extraContainer).show();
      }
    });
  }
  function setupRadioInput(inputSelector, displaySelector, containerSelector) {
    var oldValue = $("".concat(inputSelector, ":checked")).val();
    if (oldValue) {
      $(displaySelector).text(oldValue);
      $(containerSelector).show();
    }
    $(inputSelector).change(function () {
      var value = $("".concat(inputSelector, ":checked")).val();
      if (value) {
        $(displaySelector).text(value);
        $(containerSelector).show();
      }
    });
  }

  // Utility Functions
  function isScrolledIntoView(elem) {
    var docViewTop = $(window).scrollTop();
    var docViewBottom = docViewTop + ($(window).height() + 200);
    var elemTop = elem.offset().top;
    var elemBottom = elemTop + elem.height();
    return elemBottom <= docViewBottom && elemTop >= docViewTop;
  }
});