"use strict";

jQuery(document).ready(function ($) {
  var _this2 = this;
  // Foundation Initialization
  $(document).foundation();

  // Navigation Menu Toggle
  var body = $("body");
  var nav = $(".nav-container");
  $("#openMenu").click(function () {
    nav.addClass("open");
    body.addClass("open");
  });
  $("#close-menu").click(function () {
    nav.removeClass("open");
    body.removeClass("open");
  });
  $(document).mouseup(function (e) {
    if (!nav.is(e.target) && nav.has(e.target).length === 0) {
      body.removeClass("open");
      nav.removeClass("open");
    }
  });

  // Magnific Popup
  $('.popup-youtube, .popup-vimeo, .popup-gmaps').magnificPopup({
    type: 'iframe',
    mainClass: 'mfp-fade',
    removalDelay: 160,
    preloader: false,
    fixedContentPos: false
  });

  // Site Navigation Children Classes
  var ul = $("nav#site-navigation ul li ul");
  var li = ul.closest("li");
  li.addClass("hasChildren");
  li.find("a").append("<a href='javascript:void(0)' class='expandChild' title='Child item'></a>");

  // Search Toggle
  $(".open-search-from").click(function () {
    $("section").addClass("open-search");
    body.addClass("search-main");
  });
  $("#deleteSearch").click(function () {
    $("section").removeClass("open-search");
    body.removeClass("search-main");
  });

  // Scroll Animation
  $(window).on('scroll touchmove', function () {
    $('.fade-in').each(function () {
      if (isScrolledIntoView($(this))) {
        $(this).addClass('animation-started');
      }
    });
  });

  // Options Handling with Cookies
  initializeCookies();
  function initializeCookies() {
    var cookieName = 'options';
    var packageId = $('#package-options').data('package-id');
    var optionsArray = Cookies.get(cookieName) ? Cookies.get(cookieName).split(',') : [];
    if (optionsArray.length === 1 && optionsArray[0] === "[]") {
      optionsArray = [];
    }

    // Initialize the cookie if it doesn't exist
    if (!Cookies.get(cookieName)) {
      Cookies.set(cookieName, JSON.stringify([]));
      Cookies.set('options_package_id', packageId);
    }
    console.log(optionsArray);

    // Set up option handlers
    setupOptionHandlers(optionsArray);
  }
  function setupOptionHandlers(optionsArray) {
    // Wait a bit before setting the initial values
    setTimeout(function () {
      $('#input_3_27').val(0); // Reset or set a default value
      $('#input_3_4').val(optionsArray.join()); // Set values from cookie

      // Loop through the options array and mark them as selected
      optionsArray.forEach(function (id) {
        $(".option-cards article[data-id='" + id + "']").addClass("is-selected");
      });

      // Show or hide the extras based on options
      toggleExtrasVisibility(optionsArray);

      // Update the added-options list with the current selected options
      updateAddedOptionsList(optionsArray);
    }, 10);

    // Hide the extras section initially
    $('.extras').hide();

    // Handle the removal of options when clicked in the added list
    $("ul.added-options").on("click", "li", function () {
      var id = $(this).data('option-id');
      $(this).remove();

      // Remove the option from the array and update the cookie
      optionsArray.splice(optionsArray.indexOf(id), 1);
      Cookies.set('options', optionsArray.join());

      // Unselect the option card
      $(".option-cards article[data-id='" + id + "']").removeClass("is-selected");

      // Show or hide the extras based on options
      toggleExtrasVisibility(optionsArray);

      // Update the added-options list with the current selected options
      updateAddedOptionsList(optionsArray);
    });

    // Handle clicking option cards to toggle selection
    $(".option-cards article").each(function () {
      var _this = this;
      $(this).click(function () {
        var title = $(_this).find('.title').text();
        var id = $(_this).data('id');
        $(_this).toggleClass("is-selected");

        // Show or hide the extras based on selection
        if ($(_this).hasClass("is-selected")) {
          // Add the ID only if it's not already in the optionsArray
          if (!optionsArray.includes(id)) {
            optionsArray.push(id);
            $(".added-options").append("<li data-option-id=\"".concat(id, "\"><i class=\"far fa-trash-alt\"></i>").concat(title, "</li>"));
          }
        } else {
          optionsArray.splice(optionsArray.indexOf(id), 1);
          $(".added-options li[data-option-id=\"".concat(id, "\"]")).remove();
        }

        // Update the cookies with the latest selection
        Cookies.set('options', optionsArray.join());

        // Show or hide the extras based on options
        toggleExtrasVisibility(optionsArray);

        // Update the added-options list with the current selected options
        updateAddedOptionsList(optionsArray);
      });
    });
  }

  // Function to toggle the visibility of the extras section based on options
  function toggleExtrasVisibility(optionsArray) {
    if (optionsArray.length > 0) {
      $('.extras').show(); // Show if there are options
    } else {
      $('.extras').hide(); // Hide if no options
    }
  }

  // Function to update the added-options list inside the .extras section
  function updateAddedOptionsList(optionsArray) {
    var addedOptionsList = $(".added-options");
    addedOptionsList.empty(); // Clear the list first

    if (optionsArray.length === 0) {
      return; // No options to display, so just return
    }

    console.log(optionsArray);

    // Loop through the optionsArray and append each selected option as an <li>
    optionsArray.forEach(function (id) {
      var title = $(".option-cards article[data-id='" + id + "']").find('.title').text();
      addedOptionsList.append("<li data-option-id=\"".concat(id, "\"><i class=\"far fa-trash-alt\"></i>").concat(title, "</li>"));
    });
  }

  // Form Input Display Handlers
  setupFormInput("#input_3_6", '#formDate', '#formDateContainer', '.form-information');
  setupFormInput("#input_3_25", '#formTime', '#formTimeContainer');
  setupFormInput("#input_3_8", '#formAdres', '#formAdresContainer');
  setupFormInput("#input_3_12", '#formPersons', '#formPersonContainer');
  setupRadioInput("#input_3_26", '#formFoodtruck', '#formFoodtruckContainer');
  function setupFormInput(inputSelector, displaySelector, containerSelector, extraContainer) {
    var oldValue = $(inputSelector).val();
    if (oldValue) {
      $(displaySelector).text(oldValue);
      $(containerSelector).show();
      if (extraContainer) $(extraContainer).show();
    }
    $(inputSelector).change(function () {
      var value = $(this).val();
      if (value) {
        $(displaySelector).text(value);
        $(containerSelector).show();
        if (extraContainer) $(extraContainer).show();
      }
    });
  }
  function setupRadioInput(inputSelector, displaySelector, containerSelector) {
    var oldValue = $("".concat(inputSelector, ":checked")).val();
    if (oldValue) {
      $(displaySelector).text(oldValue);
      $(containerSelector).show();
    }
    $(inputSelector).change(function () {
      var value = $("".concat(inputSelector, ":checked")).val();
      if (value) {
        $(displaySelector).text(value);
        $(containerSelector).show();
      }
    });
  }

  // Utility Functions
  function isScrolledIntoView(elem) {
    var docViewTop = $(window).scrollTop();
    var docViewBottom = docViewTop + ($(window).height() + 200);
    var elemTop = elem.offset().top;
    var elemBottom = elemTop + elem.height();
    return elemBottom <= docViewBottom && elemTop >= docViewTop;
  }

  // if ($('body').hasClass('single-package')) {
  //     $('.sub-dropdown').slideDown();
  // }

  $(".open-sub-menu").click(function () {
    $('.sub-dropdown').slideToggle();
    $(_this2).toggleClass('open');
  });
  $(".step-2").click(function () {
    $(".step-2").hide();
    $("h1").hide();
    $(".h1").slideDown();
    $('.package-intro').slideUp();
    $('.sub-dropdown').slideUp();
    $('.package-content').slideDown();
    $('.package-btn').show();
  });
  $("#submitForm").click(function () {
    $("#gform_submit_button_3").click();
  });
  $(".openPopup").on("click", function () {
    $("#popup-overlay, #popup").fadeIn("slow");
  });

  // Close popup when the close button or overlay is clicked
  $("#closePopup, #popup-overlay").on("click", function () {
    $("#popup-overlay, #popup").fadeOut("slow");
  });
});